import React from 'react'
import { data } from './Data'
import IconCloud from '../img/mobileAppIcons/iconCloud.svg'
import IconArrow from '../img/iconArrow.svg'
import IconIptv from '../img/mobileAppIcons/iconIptv.svg'

function MobileApp() {
   return (
      <div className='w-full pb-40 bg-gradient-to-t from-[#F6F4EF]'>
         <div className='max-w-[1280px] mx-auto font-body px-4'>

            <div className='max-w-[800px] mx-auto text-center mt-28 mb-20 max-md:mt-20 max-md:mb-10'>
               <h2 className='text-5xl font-bold max-md:text-4xl'>Mobile App and Software Blockchain Solution</h2>
            </div>

            <div className='flex max-lg:flex-col'>
               {/* 1 block */}
               <div className='bg-[#FF5A26] min-w-[50%] min-h-[40rem] rounded-xl text-white flex flex-col justify-between px-7 py-7'>
                  <div className='max-w-[32rem]'>
                     <p>{data[8].description}</p>
                  </div>

                  <div className='max-w-[18rem]'>
                     <img src={IconCloud} alt="icon cloud" />
                     <h3 className='text-5xl font-medium pt-10 max-md:text-3xl'>{data[8].title}</h3>
                  </div>

               </div>

               {/* 2 block */}
               <div className='min-w-[25%] flex flex-col justify-between px-3 max-lg:px-0'>

                  <div className='bg-[#EAE7E0] min-h-[19.6rem] rounded-xl flex flex-col justify-between px-7 py-7 max-lg:my-3'>
                     <div className='max-w-[15rem]'>
                        <h3 className='text-3xl font-bold'>{data[9].title}</h3>
                     </div>
                     <div>
                        <img src={IconArrow} alt="icon arrow" />
                        <p className='pt-4'>{data[9].description}</p>
                     </div>

                  </div>

                  <div className='bg-black min-h-[19.6rem] rounded-xl flex flex-col justify-between px-7 py-7 max-lg:mb-3'>
                     <div>
                        <h3 className='text-white text-3xl font-medium'>{data[10].title}</h3>
                     </div>
                     <div>
                        <img src={IconArrow} alt="icon arrow" />
                        <p className='text-slate-500 pt-4'>{data[10].description}</p>
                     </div>

                  </div>
               </div>

               {/* 3 block */}
               <div className='min-w-[25%] min-h-[40rem] bg-white border-2 rounded-xl flex flex-col justify-between px-7 py-7'>
                  <div>
                     <h3 className='text-3xl font-bold'>{data[11].title}</h3>
                  </div>
                  <div className='flex justify-center'>
                     <img src={IconIptv} alt="icon iptv" />
                  </div>
                  <div>
                     <p className='text-slate-500'>{data[11].description}</p>
                  </div>

               </div>
            </div>

         </div>
      </div>
   )
}

export default MobileApp